import logo from './logo.svg';
import './App.css';
import { useEffect } from 'react';

function App() {  
  useEffect(() => {
    window.location.href = "https://wa.me/5218117297738";
  }, []);
  return (
    <div className="App">
      <h1>Direccionando al bot de Salinas Victoria, NL...</h1>
    </div>
  );
}

export default App;
